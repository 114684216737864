.header-user-container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 80px; /* Largura colapsada */
  background-color: #0c2746; /* Cor de fundo */
  color: white;
  transition: width 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.header-user-container.expanded {
  width: 200px; /* Largura expandida */
}

.header-user-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0px;
  width: 100%;
}

.header-user-container.collapsed {
  width: 50px;
}

.header-user-logo {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  transition: opacity 0.3s;
}

.header-user-container.collapsed .header-user-logo {
  opacity: 0;
}

.header-user-nav-button {
  display: flex;
  align-items: center;
  margin: 1px 0;
  cursor: pointer;
  width: 100%;
  padding: 7px;
  transition: background-color 0.2s;
}

.header-user-nav-button img.nav-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.header-user-nav-button:hover {
  background-color: #0c317c;
}

.header-user-nav-button span {
  display: inline-block;
  opacity: 0;
  transition: opacity 0.3s;
}

.header-user-container.expanded .header-user-nav-button span {
  opacity: 1;
}

.header-user-footer {
  margin-top: auto;
  padding: 10px;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column; /* Organiza os itens em coluna */
  align-items: center;    /* Centraliza os itens */
}

.footer-title {
  font-weight: bold;
  display: block;
  font-size: 25px;
  margin-top: 30px;
}

.footer-username {
  font-size: 20px;
  display: block;
}

.header-user-container.collapsed .header-user-footer {
  opacity: 0;
}

/* Estilos da foto e nome do robô */
.footer-robo-foto {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.footer-robo-nome {
  font-size: 18px;
  margin-bottom: 10px;
}
@media (max-height: 930px) {
  .header-user-nav-button {
      padding: 7px; 
  }

  .header-user-nav-button img.nav-icon {
      width: 25px;
      height: 25px;
  }

  .header-user-nav-button span {
      font-size: 15px; 
  }

  .footer-title {
      font-size: 23px;
      margin-top: 10px;
  }

  .footer-username {
      font-size: 15px;
  }

  .footer-robo-foto {
      width: 75px;
      height: 75px;
  }

  .footer-robo-nome {
      font-size: 15px;
  }
}

@media (max-height: 860px) {
  .header-user-nav-button {
      padding: 6px;
  }

  .header-user-nav-button img.nav-icon {
      width: 25px;
      height: 25px;
  }

  .header-user-nav-button span {
      font-size: 14px;
  }

  .footer-title {
      font-size: 18px;
      margin-top: 10px;
  }

  .footer-username {
      font-size: 14px;
  }

  .footer-robo-foto {
      width: 75px;
      height: 75px;
  }

  .footer-robo-nome {
      font-size: 14px;
  }
}
@media (max-height: 820px) {
  .header-user-nav-button {
      padding: 5px;
  }

  .header-user-nav-button img.nav-icon {
      width: 22px;
      height: 22px;
  }

  .header-user-nav-button span {
      font-size: 14px;
  }

  .footer-title {
      font-size: 18px;
      margin-top: 10px;
  }

  .footer-username {
      font-size: 14px;
  }

  .footer-robo-foto {
      width: 75px;
      height: 75px;
  }

  .footer-robo-nome {
      font-size: 14px;
  }
}
@media (max-height: 750px) {
  .header-user-nav-button {
      padding: 4px;
  }

  .header-user-nav-button img.nav-icon {
      width: 20px;
      height: 20px;
  }

  .header-user-nav-button span {
      font-size: 14px;
  }

  .footer-title {
      font-size: 18px;
      margin-top: 10px;
  }

  .footer-username {
      font-size: 14px;
  }

  .footer-robo-foto {
      width: 75px;
      height: 75px;
  }

  .footer-robo-nome {
      font-size: 14px;
  }
}
@media (max-height: 700px) {
  .header-user-nav-button {
      padding: 4px;
  }

  .header-user-nav-button img.nav-icon {
      width: 18px;
      height: 18px;
  }

  .header-user-nav-button span {
      font-size: 13px;
  }

  .footer-title {
      font-size: 16px;
      margin-top: 5px;
  }

  .footer-username {
      font-size: 13px;
  }

  .footer-robo-foto {
      width: 60px;
      height: 60px;
  }

  .footer-robo-nome {
      font-size: 13px;
  }
}

@media (max-height: 650px) {
  .header-user-nav-button {
      padding: 5px;
  }

  .header-user-nav-button img.nav-icon {
      width: 18px;
      height: 18px;
  }

  .header-user-nav-button span {
      font-size: 12px;
  }

  .footer-title {
      font-size: 14px;
      margin-top: 5px;
  }

  .footer-username {
      font-size: 12px;
  }

  .footer-robo-foto {
      width: 55px;
      height: 55px;
  }

  .footer-robo-nome {
      font-size: 12px;
  }
}

@media (max-height: 600px) {
  .header-user-nav-button {
      padding: 3px;
  }

  .header-user-nav-button img.nav-icon {
      width: 15px;
      height: 15px;
  }

  .header-user-nav-button span {
      font-size: 11px;
  }

  .footer-title {
      font-size: 13px;
      margin-top: 4px;
  }

  .footer-username {
      font-size: 11px;
  }

  .footer-robo-foto {
      width: 50px;
      height: 50px;
  }

  .footer-robo-nome {
      font-size: 11px;
  }
}

@media (max-height: 550px) {
  .header-user-nav-button {
      padding: 2px;
  }

  .header-user-nav-button img.nav-icon {
      width: 14px;
      height: 14px;
  }

  .header-user-nav-button span {
      font-size: 10px;
  }

  .footer-title {
      font-size: 12px;
      margin-top: 3px;
  }

  .footer-username {
      font-size: 10px;
  }

  .footer-robo-foto {
      width: 45px;
      height: 45px;
  }

  .footer-robo-nome {
      font-size: 10px;
  }
}

@media (max-height: 500px) {
  .header-user-nav-button {
      padding: 2px;
  }

  .header-user-nav-button img.nav-icon {
      width: 13px;
      height: 13px;
  }

  .header-user-nav-button span {
      font-size: 9px;
  }

  .footer-title {
      font-size: 10px;
      margin-top: 2px;
  }

  .footer-username {
      font-size: 9px;
  }

  .footer-robo-foto {
      width: 40px;
      height: 40px;
  }

  .footer-robo-nome {
      font-size: 9px;
  }
}

@media (max-height: 450px) {
  .header-user-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .footer-title {
      font-size: 10px;
      display: none; /* Esconde "Área do Cliente" apenas se necessário */
  }

  .footer-robo-nome {
      font-size: 10px;
  }

  .footer-robo-foto {
      width: 40px;
      height: 40px;
  }
}

@media (max-height: 400px) {
  .header-user-footer {
      display: none; /* Somente esconde o rodapé em telas extremamente pequenas */
  }
}


