/* Container principal */
.manage-mensagens-container {
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

/* Imagem */
.msg-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  height: auto;
  margin-bottom: 20px;
}

/* Responsividade */
@media (max-width: 600px) {
  .manage-mensagens-container {
    max-width: 400px;
    margin-left: 25px;
  }
}

/* Títulos */
.manage-mensagens-container h2 {
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.manage-mensagens-container h5 {
  color: #f50505;
  text-align: center;
  font-weight: bold;
}

/* Texto importante */
.important-text {
  color: red;
  font-weight: bold;
}

/* Lista de mensagens */
.mensagens-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Item da mensagem */
.mensagem-item {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mensagem-item h3 {
  margin: 0 0 10px;
  color: #0c2746;
}

/* Conteúdo da mensagem */
.mensagem-content p {
  margin: 0 0 10px;
}

/* Seção de observação */
.observacao-display {
  background-color: #f1f5f9; /* Fundo cinza claro para destacar a observação */
  padding: 10px 15px; /* Espaçamento interno para maior conforto visual */
  border-left: 4px solid #0c2746; /* Barra lateral azul escura para destaque */
  border-radius: 4px; /* Bordas levemente arredondadas */
  margin-top: 15px; /* Espaço acima da observação */
  margin-bottom: 10px;
}

.observacao-display h4 {
  margin: 0 0 5px; /* Espaço abaixo do título "Observação" */
  color: #0c2746; /* Mesma cor do título da mensagem para consistência */
  font-size: 1rem; /* Tamanho de fonte um pouco menor */
  font-weight: 600; /* Negrito leve */
}

.observacao-display p {
  margin: 0; /* Remove margens extras */
  color: #444; /* Cor um pouco mais escura para legibilidade */
  font-size: 0.9rem; /* Tamanho de fonte menor para diferenciar do texto principal */
  line-height: 1.5; /* Altura da linha para melhor legibilidade */
}

/* Área de edição */
.edit-area {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.edit-area textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Botões */
.edit-button,
.save-button,
.cancel-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
}

.edit-button {
  background-color: #0c2746;
}

.edit-button:hover {
  background-color: #0c2746;
}

.save-button {
  background-color: green;
}

.save-button:hover {
  background-color: darkgreen;
}

.cancel-button {
  background-color: red;
}

.cancel-button:hover {
  background-color: darkred;
}