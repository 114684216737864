.agile-agents-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.agile-agents-wrapper {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin-left: 250px;
}

@media (max-width: 1024px) {
    .agile-agents-wrapper {
        margin-left: 200px;
    }
}

@media (max-width: 768px) {
    .agile-agents-wrapper {
        margin-left: 100px;
        max-width: 90%;
    }
}

@media (max-width: 600px) {
    .agile-agents-wrapper {
        margin-left: 50px;
        max-width: 80%;
    }
}

@media (max-width: 550px) {
    .agile-agents-wrapper {
        margin-left: 55px;
        max-width: 70%;
        padding: 15px;
    }

    .agile-table th, .agile-table td {
        padding: 8px;
        font-size: 0.9rem;
    }

    .save-button, .cancel-button {
        padding: 6px 10px;
        font-size: 0.85rem;
    }
}

@media (max-width: 515px) {
    .agile-agents-wrapper {
        margin-left: 55px;
        max-width: 60%;
        padding: 15px;
    }
}

.agile-agents-wrapper h2 {
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.agile-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
}

.agile-table th, .agile-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.agile-table th {
    background-color: #0c2746;
    color: white;
}

.mensagem-item:nth-child(even) {
    background-color: #f2f2f2;
}

.save-button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: green;
    color: white;
}

.save-button:hover {
    background-color: darkgreen;
}

.cancel-button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: red;
    color: white;
}

.cancel-button:hover {
    background-color: darkred;
}
.agile-agents-wrapper img {
    width: 550px;
    height: 300px;
    display: block;
    margin: 0 auto 20px;
  }
  .agent-column {
    width: 147px; /* Ajuste o valor conforme necessário */
  }
  